<template>
	<v-bottom-navigation
		v-if="state.showTabBar"
		height="86px"
	>
		<template
			v-for="(nav, index) in tabs"
			:key="index"
		>
			<Dropdown
				v-if="nav.items"
				:items="nav.items"
				location="top"
				:content-full-width="true"
				offset="20"
				@click-item="item => goTo(item.path)"
			>
				<Tab
					:active="isTabActive(nav)"
					:icon="nav.icon"
					:label="nav.label"
					:show-badge="nav?.showBadge"
				/>
			</Dropdown>
			<Tab
				v-else
				:icon="nav.icon"
				:label="nav.label"
				:active="isTabActive(nav)"
				:show-badge="nav?.showBadge"
				@click="goTo(nav.path)"
			/>
		</template>

		<Tab
			icon="menu"
			label="components.navigations.label.menu"
			:active="false"
			@click="toggleTabMenu(!state.showTabMenu)"
		/>
	</v-bottom-navigation>

	<TabMenu
		:show-menu="state.showTabMenu"
		@toggle-menu="value => toggleTabMenu(value)"
	/>
</template>

<script lang="ts" setup>
import Tab from "./Tab.vue";
import TabMenu from "./TabMenu.vue";
import type { RootNavItem } from "@/components/Navigation/types";
import Dropdown from "@/components/Dropdown.vue";

interface TabbarState {
	showTabMenu: boolean;
	showTabBar: boolean;
}

const state = reactive<TabbarState>({
	showTabMenu: false,
	showTabBar: false,
});

const { routes } = storeToRefs(useNavStore());
const tabs = computed(() => routes.value.filter(item => item.showOnTabbar));
onMounted(() => {
	window.addEventListener("resize", handleResize);
	handleResize();
});

const handleResize = () => {
	const clientWidth = document.documentElement.clientWidth;
	if (clientWidth <= 800) {
		state.showTabBar = true;
	} else {
		state.showTabBar = false;
	}
};

const route = useRoute();
const router = useRouter();
const isTabActive = (nav: RootNavItem) => {
	if (nav.items) {
		return nav.items.filter(item => item.path === route.path).length > 0;
	} else return nav.path === route.path;
};

const goTo = (path: string | undefined) => path && router.push(path);

const toggleTabMenu = (value: boolean) => (state.showTabMenu = value);
</script>

<style lang="scss" scoped>
:deep(.v-bottom-navigation__content) {
	@apply p-[10px];
	div + div {
		@apply ml-[5px];
	}
}
</style>
