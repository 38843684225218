<template>
	<v-text-field
		:placeholder="props.placeholder"
		:focused="props.focused"
		:disabled="props.disabled"
		:model-value="props.modelValue"
		density="compact"
		hide-details
		@update:model-value="updateValue"
	>
		<template #prepend-inner>
			<Icon name="md-search" />
		</template>
		<template
			v-if="props?.clearable && props?.modelValue"
			#append-inner
		>
			<Icon
				class="icon-clear"
				name="md-close"
				@click="clearInput"
			/>
		</template>
	</v-text-field>
</template>

<script lang="ts" setup>
import Icon from "@/components/Icon/index.vue";

/**
 * @deprecated use SearchInput from @tengiva/components
 */

interface TextFieldProps {
	modelValue: string | null;
	placeholder?: string;
	disabled?: boolean;
	focused?: boolean;
	clearable?: boolean;
}
const props = defineProps<TextFieldProps>();

const emits = defineEmits(["update:modelValue"]);

const updateValue = (value: string) => emits("update:modelValue", value);
const clearInput = () => emits("update:modelValue", "");
</script>

<style lang="scss" scoped>
.v-input {
	// Default state
	:deep(.v-field__prepend-inner),
	:deep(.v-field__append-inner) {
		@apply p-[0];
		.material-symbols-outlined {
			@apply text-[20px] text-neutral-500 leading-[20px] self-center;
		}
	}

	:deep(.v-field__outline::after) {
		@apply border-none;
	}

	:deep(.v-field__outline::before) {
		@apply border-neutral-200 border-[1px] rounded-[4px] opacity-100;
	}

	:deep(.v-field__overlay) {
		@apply bg-transparent;
	}

	// Focused
	:deep(.v-field--focused) {
		.v-field__outline::before {
			box-shadow: 0px 0 3px 1px #0c7ce6;
			@apply border-black;
		}
		.v-field__outline::after {
			@apply border-none;
		}
	}

	// Hovering and Filling
	:deep(.v-field:hover),
	:deep(.v-field--dirty) {
		.v-field__input::placeholder {
			@apply text-black opacity-100;
		}
		.material-symbols-outlined {
			@apply text-black;
		}
		.icon-clear {
			@apply text-red-200 cursor-pointer;
		}
	}

	// Disabled
	:deep(.v-field--disabled) {
		.v-field__outline::before {
			border-image: none;
			border: none;
		}
	}
}
</style>
