<template>
	<div :class="`nav-divider ${props?.size || 'normal'}`" />
</template>

<script lang="ts" setup>
const props = defineProps<{ size?: "normal" | "small" }>();
</script>

<style lang="scss" scoped>
.nav-divider {
	@apply my-[16px] h-[1px] bg-neutral-100;

	&.normal {
		@apply mx-[16px];
		@apply mobile:mx-[20px];
	}

	&.small {
		@apply mx-[15px];
	}
}
</style>
