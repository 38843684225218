<template>
	<v-list-group
		v-if="!navItemProps?.collapsed"
		:value="navItemProps.id"
	>
		<template #activator="{ props }">
			<div
				v-bind="props"
				class="sidenav-label"
				:class="{ active: isActive(navItemProps?.path) }"
				@click="navItemProps?.path && $router.push(navItemProps.path)"
			>
				<Icon
					v-if="navItemProps?.icon"
					class="prepend-icon"
					:name="navItemProps?.icon"
					:filled="isActive(navItemProps?.path)"
					:optical-size="20"
				/>

				<Badge
					dot
					:visible="navItemProps?.showBadge || false"
				>
					<p :class="[navItemProps?.icon ? 'label-small-padding' : 'label-large-padding']">
						{{ navItemProps.label }}
					</p>
				</Badge>

				<Icon
					v-if="navItemProps.items?.length"
					class="append-icon"
					:name="!navItemProps?.opened ? 'md-expand_more' : 'md-expand_less'"
					:filled="isActive(navItemProps?.path)"
					:optical-size="20"
				/>
			</div>
		</template>

		<div
			v-for="(item, i) in navItemProps.items"
			:key="i"
		>
			<div
				class="sidenav-label pl-0"
				:class="{ active: isActive(item?.path) }"
				@click="item?.path && $router.push(item.path)"
			>
				<Icon
					v-if="item?.icon"
					class="prepend-icon"
					:name="item.icon"
					:filled="isActive(item?.path)"
					:optical-size="20"
				/>
				<p :class="['flex-grow', item.icon ? 'label-small-padding' : 'label-large-padding']">
					{{ item.label }}
				</p>
				<Badge
					v-if="!!item?.inbox"
					:dot="false"
					:visible="!!item?.inbox"
					:content="item?.inbox"
					inline
				>
				</Badge>
			</div>
		</div>
	</v-list-group>
	<v-list-group
		v-else
		class="navitem-collapsed"
		:class="{ active: isAnyChildActive(navItemProps?.items) }"
		@click.capture="navItemProps?.path && $router.push(navItemProps.path)"
	>
		<template #activator="{ props: listGroupProps }">
			<v-menu
				v-if="navItemProps?.items"
				v-bind="listGroupProps"
				location="right"
				offset="30"
				:open-on-hover="!disabledOpenOnHover"
			>
				<template #activator="{ props }">
					<Icon
						v-if="navItemProps?.icon"
						class="self-center"
						v-bind="props"
						:name="navItemProps.icon"
						:filled="isAnyChildActive(navItemProps?.items)"
						:optical-size="20"
					/>
				</template>

				<v-list>
					<v-list-item
						v-for="(item, index) in navItemProps.items"
						:key="index"
						@click="item?.path && $router.push(item.path)"
					>
						{{ item.label }}
					</v-list-item>
				</v-list>
			</v-menu>
			<Icon
				v-else
				class="self-center"
				v-bind="listGroupProps"
				:name="navItemProps.icon || ''"
				:filled="isAnyChildActive(navItemProps?.items)"
				:optical-size="20"
			/>
		</template>
	</v-list-group>
</template>

<script lang="ts" setup>
import Badge from "@/components/Badge.vue";
import type { RootNavItem, ChildNavItem } from "@/components/Navigation/types";

interface NavItemProps extends Partial<RootNavItem> {
	id: string;
	icon?: string;
	label: string;
	path?: string;
	showBadge?: boolean;
	disabled?: boolean;
	collapsed?: boolean;
	opened: boolean;
	items?: ChildNavItem[];
}
const navItemProps = defineProps<NavItemProps>();

// Toggle active style based on route path
const route = useRoute();
const isActive = (navPath: string | undefined): boolean => navPath === route.path;
const isAnyChildActive = (navItems: RootNavItem["items"]) => {
	if (!navItems || navItems.length === 0) return navItemProps.path === route.path;
	return navItems.filter(item => item.path === route.path).length > 0;
};

const disabledOpenOnHover = computed(() => "ontouchstart" in document.documentElement);
</script>

<style lang="scss" scoped>
.label-small-padding {
	@apply pl-[16px];
}

.label-large-padding {
	@apply pl-[56px];
}

.v-list-group {
	@apply mb-[4px];
}

// Default
.sidenav-label {
	@apply flex items-center py-[12px] pl-[16px] pr-[32px] leading-[24px] cursor-pointer;

	&.collapsed {
		@apply pl-0;
	}
	.append-icon {
		@apply flex-grow text-right;
	}

	&.active {
		@apply bg-black text-white rounded-l-[24px];
		&:hover {
			@apply bg-black text-white rounded-l-[24px];
		}
	}

	&:hover {
		@apply bg-neutral-50 rounded-l-[24px];
	}
}

// Collasped
.navitem-collapsed {
	@apply flex justify-center cursor-pointer mx-[16px] w-[48px] h-[48px] leading-[48px] text-black;
	&.active {
		@apply bg-black text-white rounded-[100px];
		&:hover {
			@apply bg-black text-white rounded-[100px];
		}
	}

	&:hover {
		@apply bg-neutral-50 rounded-[100px];
	}
}
</style>
