<template>
	<v-navigation-drawer
		:model-value="state.visible"
		disable-resize-watcher
		disable-route-watcher
		:width="state.width"
		style="top: 80px"
		:rail="state.collapsed"
		:rail-width="80"
		:permanent="state.visible"
	>
		<v-list
			v-model:opened="state.openedItems"
			class="navigation-list"
			:class="{ collapsed: state.collapsed }"
			@update:opened="updateOpenedItems"
		>
			<div
				v-for="item in routes"
				:key="item.id"
			>
				<NavItem
					v-if="!item.hidden"
					v-bind="item"
					:collapsed="state.collapsed"
					:opened="itemOpened(item.id)"
				/>
			</div>

			<NavDivider />
			<NavItem
				id="collapse"
				key="collapse"
				:icon="state.collapsed ? 'md-chevron_right' : 'md-chevron_left'"
				:label="$t('components.navigations.label.collapse')"
				:collapsed="state.collapsed"
				:opened="false"
				@click.capture="updateCollapsed"
			/>
		</v-list>

		<template #append>
			<NavFooter
				:hide-text="state.collapsed"
				:logo-icon-only="state.collapsed"
			/>
		</template>
	</v-navigation-drawer>
</template>

<script lang="ts" setup>
import NavItem from "../NavItem.vue";
import NavDivider from "../NavDivider.vue";
import NavFooter from "../NavFooter.vue";

import type { RootNavItem, ChildNavItem } from "../types";
const route = useRoute();
const { routes } = storeToRefs(useNavStore());

interface SideNavState {
	collapsed: boolean;
	width: string | number;
	visible: boolean;
	openedItems: string[];
}

const state = reactive<SideNavState>({
	collapsed: false,
	width: 300,
	visible: false,
	openedItems: [],
});

onMounted(() => {
	window.addEventListener("resize", handleResize);
	handleResize();
	state.openedItems = findRootNavItemId(routes.value);
});

const handleResize = () => {
	const clientWidth = document.documentElement.clientWidth;
	if (clientWidth < 1280) {
		state.collapsed = true;
		state.visible = true;
		if (clientWidth < 801) {
			state.visible = false;
		}
	} else {
		state.collapsed = false;
		state.visible = true;
	}
};

const updateOpenedItems = (items: any) => (state.openedItems = items);

const itemOpened = (id: string) => state.openedItems.includes(id);

const updateCollapsed = () => (state.collapsed = !state.collapsed);

const findRootNavItemId = (navItems: RootNavItem[] | ChildNavItem[]): string[] => {
	return navItems
		.map(item => {
			if (item?.items) {
				return findRootNavItemId(item.items);
			} else {
				return item.path === route.path ? item.id.split("_")[0] : null;
			}
		})
		.flat()
		.filter(item => item !== null) as string[];
};
</script>

<style lang="scss" scoped>
.navigation-list {
	@apply pt-[16px] pl-[16px];
	&.collapsed {
		@apply pl-0;
	}
}
</style>
