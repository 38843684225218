<template>
	<v-menu
		:open-on-hover="dropdownProps.openOnHover || false"
		:location="dropdownProps.location || 'end'"
		:class="{ 'content-full-width': dropdownProps.contentFullWidth }"
		:offset="dropdownProps.offset"
	>
		<template #activator="{ props }">
			<div v-bind="props">
				<slot />
			</div>
		</template>
		<v-list>
			<v-list-item
				v-for="(item, index) in dropdownProps.items"
				:key="index"
				class="dropdown-item"
			>
				<v-list-item-title @click="onClickDropdownItem(item)">
					<span class="dropdown-item-label">{{ $t(item.label) }}</span>
					<Badge
						:dot="false"
						:visible="!!item?.inbox"
						:content="item?.inbox"
						inline
					>
					</Badge>
				</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script lang="ts" setup>
import type { VMenu } from "vuetify/components/VMenu";
import Badge from "@/components/Badge.vue";

/**
 * @deprecated use Dropdown from @tengiva/components
 */

interface DropdownProps {
	items: any[];
	openOnHover?: boolean;
	location?: VMenu["location"];
	contentFullWidth?: boolean;
	offset?: VMenu["offset"];
}

const dropdownProps = defineProps<DropdownProps>();
const emits = defineEmits<{ (e: "clickItem", item: any): void }>();

const onClickDropdownItem = (item: any) => {
	if (item.onClick) item.onClick();
	emits("clickItem", item);
};
</script>

<style lang="scss" scoped>
.dropdown-item {
	@apply cursor-pointer mx-[8px] rounded-[4px];
	&:hover {
		@apply bg-neutral-50;
	}
	:deep(.v-list-item-title) {
		@apply flex;
		.dropdown-item-label {
			@apply flex-grow;
		}
	}
}

.content-full-width {
	:deep(.v-overlay__content) {
		width: calc(100% - 20px) !important;
		max-width: calc(100% - 20px) !important;
		left: 10px !important ;
		right: 10px !important;
		margin: 0 auto;
	}
}
</style>
