<template>
	<v-navigation-drawer
		width="320"
		disable-resize-watcher
		disable-route-watcher
		:model-value="props.showMenu"
		location="right"
		temporary
		@update:model-value="toggleTabMenu"
	>
		<div class="tabmenu-wrapper">
			<Icon
				class="close-icon"
				name="close"
				@click="toggleTabMenu(false)"
			/>
			<v-list
				v-model:opened="state.openedItems"
				class="navigation-list"
				@update:opened="updateOpenedItems"
			>
				<NavItem
					v-for="item in navItems"
					v-bind="item"
					:key="item.id"
					:collapsed="false"
					:opened="itemOpened(item.id)"
				/>
			</v-list>
			<NavDivider size="small" />
			<Dropdown
				:items="items"
				location="bottom"
				@click-item="onClickProfileItem"
			>
				<div class="profile">
					<v-avatar
						class="mr-[4px]"
						color="black"
						size="40"
						>{{ userInitial }}</v-avatar
					>
					<span class="flex-grow">{{ userName }}</span>
					<Icon name="md-expand_more" />
				</div>
			</Dropdown>
		</div>
		<template #append>
			<NavFooter class="footernote" />
		</template>
	</v-navigation-drawer>
</template>

<script lang="ts" setup>
import NavItem from "../NavItem.vue";
import NavDivider from "../NavDivider.vue";
import NavFooter from "../NavFooter.vue";
import Dropdown from "@/components/Dropdown.vue";
import Icon from "@/components/Icon/index.vue";

interface TabMenuProps {
	showMenu: boolean;
}
const props = defineProps<TabMenuProps>();

interface SideNavState {
	openedItems: string[];
}
const state = reactive<SideNavState>({
	openedItems: [],
});

const { routes } = storeToRefs(useNavStore());

const navItems = computed(() => routes.value.filter(item => item.showOnTabbar === false));

const itemOpened = (id: string) => state.openedItems.includes(id);
const updateOpenedItems = (items: any) => (state.openedItems = items);

const emits = defineEmits<{ (e: "toggleMenu", value: boolean): void }>();
const toggleTabMenu = (value: boolean) => emits("toggleMenu", value);

const userInitial = "MJ";
const userName = "Matthew Johansson";

const onClickProfileItem = (item: any) => {
	switch (item.id) {
		default:
			console.log(item);
	}
};

const items = [
	{
		id: "sign_out",
		label: "Sign out",
	},
];
</script>

<style lang="scss" scoped>
.tabmenu-wrapper {
	@apply pt-[60px] pl-[16px] h-auto;
	.close-icon {
		@apply absolute top-[16px] right-[16px] cursor-pointer;
	}
	.profile {
		@apply flex items-center justify-start pl-[16px] pr-[32px] cursor-pointer;
	}
}
.footernote {
	@apply h-[50px] mb-[34px];
}
</style>
