<template>
	<!-- @de -->
	<v-badge
		:dot="props?.dot"
		:color="badgeColor"
		:inline="props?.inline"
		:content="props?.content"
	>
		<slot />
	</v-badge>
</template>

<script lang="ts" setup>
/**
 * @deprecated use Badge from @tengiva/components
 */
interface DadgeProps {
	dot?: boolean;
	visible: boolean;
	inline?: boolean;
	content?: string | number;
}
const props = defineProps<DadgeProps>();

const badgeColor = computed(() => (props.visible ? "error" : "transparent"));
</script>

<style lang="scss" scoped>
.v-badge {
	&.v-badge--dot {
		:deep(.v-badge__wrapper) {
			@apply leading-[24px] pr-[4px];
		}
		:deep(.v-badge__badge) {
			height: 8px !important;
			width: 8px !important;
			top: 0 !important;
			left: auto !important;
			right: 0 !important;
			border-radius: 4px;
		}
	}
}
</style>
