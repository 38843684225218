<template>
	<v-app-bar :elevation="0">
		<div class="top-bar">
			<a href="/">
				<TengivaLogo
					class="top-bar-logo"
					color="black"
				/>
			</a>

			<div class="top-bar-search-box">
				<SearchInput
					v-model="state.searchInput"
					:placeholder="$t('components.top_bar.search_input.placeholder')"
					clearable
				/>
			</div>

			<div class="top-bar-control">
				<div class="inbox-group">
					<Badge
						dot
						:visible="true"
					>
						<Icon name="md-mail"></Icon>
					</Badge>
					<Badge
						dot
						:visible="true"
					>
						<Icon name="md-notifications"></Icon>
					</Badge>
				</div>

				<div class="mobile:hidden">
					<Dropdown
						:items="userProfileMenu"
						location="bottom"
						offset="8"
						@click-item="onClickProfileItem"
					>
						<div class="topbar-profile">
							<span>{{ userName }}</span>
							<v-avatar
								class="ml-[8px] mr-[4px]"
								color="black"
								size="40"
								>{{ userInitial }}</v-avatar
							>
							<Icon name="md-expand_more" />
						</div>
					</Dropdown>
				</div>
			</div>
		</div>
	</v-app-bar>
</template>

<script lang="ts" setup>
import Badge from "../Badge.vue";
import Dropdown from "../Dropdown.vue";
import TengivaLogo from "../TengivaLogo.vue";
import Icon from "@/components/Icon/index.vue";
import SearchInput from "@/components/Input/SearchInput.vue";

const state = reactive({
	searchInput: null,
});

const router = useRouter();
const { logout } = useURM();

const userStore = useUserStore();
const user = userStore.user;

const userProfileMenu = [
	{
		id: "sign_out",
		label: "components.navigations.label.sign_out",
	},
];

const userInitial = computed(() => {
	if (!user) return "";
	return `${user!.first_name.charAt(0)}${user?.last_name.charAt(0)}`;
});
const userName = computed(() => {
	if (!user) return "";
	return `${user!.first_name} ${user!.last_name}`;
});

const onClickProfileItem = (item: any) => {
	switch (item.id) {
		case "sign_out":
			logout();
			router.push("/sign-in");
			break;
		default:
	}
};
</script>

<style lang="scss" scoped>
.v-toolbar {
	@apply border-b-[1px] border-b-neutral-100 w-full;
	:deep(.v-toolbar__content) {
		@apply h-[80px] #{!important};
		@apply mobile:h-[90px] #{!important};
	}
	.top-bar {
		@apply flex flex-grow flex-wrap items-center;
		.top-bar-logo {
			@apply max-w-[300px] max-h-[60px] my-[20px] mx-[55px];
			@apply mobile:max-w-[225px] mobile:max-h-[40px] mobile:ml-[15px] mobile:mr-0 mobile:my-[10px];
		}

		.top-bar-search-box {
			@apply flex-grow ml-[40px];
			@apply mobile:order-1 mobile:w-full mobile:ml-0  mobile:px-[15px] mobile:pb-[10px];
		}

		.top-bar-control {
			@apply flex items-center gap-[24px] ml-[24px] mr-[20px];
			@apply mobile:justify-end mobile:flex-grow mobile:mx-0;
			.topbar-profile {
				@apply flex items-center cursor-pointer;
			}
		}

		.inbox-group {
			@apply flex items-center border-r-[1px] border-r-neutral-300;
			@apply mobile:border-none;
			.v-badge {
				@apply mr-[20px];
			}
		}
	}
}
</style>
